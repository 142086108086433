import ReelImage1 from "../assets/images/video-content/Reel-1.png";
import ReelImage2 from "../assets/images/video-content/Reel-2.png";
import ReelImage3 from "../assets/images/video-content/Reel-3.png";
import ReelImage4 from "../assets/images/video-content/Reel-4.png";
import ReelImage5 from "../assets/images/video-content/Reel-5.png";
import ReelImage6 from "../assets/images/video-content/Reel-6.png";
import VideoImage1 from "../assets/images/video-content/Video-1.png";
import VideoImage2 from "../assets/images/video-content/Video-2.png";
import VideoImage3 from "../assets/images/video-content/Video-3.png";
import VideoImage4 from "../assets/images/video-content/Video-4.png";
import VideoImage5 from "../assets/images/video-content/Video-5.png";
import VideoImage6 from "../assets/images/video-content/Video-6.png";

export const videoData = [
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },

  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${VideoImage6}`,
    duration: "1:20",
  },
];

export const reelData = [
  {
    title: "The Wave bla bla",
    url: `${ReelImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",

    url: `${ReelImage1}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage2}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage3}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage5}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage6}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage4}`,
    duration: "1:20",
  },
  {
    title: "The Wave bla bla",
    url: `${ReelImage1}`,
    duration: "1:20",
  },
];

export const pages = [1, 2, 3, 1293];

export const tags = ["название тега", "покороче", "более длинный текст тега"];

export const descriptionDetails = [
  { label: "Продолжительность", value: "00:00:37.333" },
  { label: "Лицензия", value: "Персональная лицензия ?" },
  { label: "Average Bitrate", value: "220 Mbps" },
  { label: "Frame Rate", value: "30.00 fps" },
  { label: "Использование", value: "Коммерческий" },

  { label: "Bit Depth", value: "24" },
  { label: "Место съемки", value: "N/A" },
  { label: "Релиз модели", value: "Нет" },
  { label: "Зацикливание", value: "Нет" },

  { label: "Релиз собственности", value: "Нет" },
  { label: "Маска Alpha Matte", value: "Да" },

  { label: "Альфа-канал", value: "Нет" },
];
