import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DreamstimeAPI = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // URL API Dreamstime с использованием CORS Proxy
    const url = 'https://cors-anywhere.herokuapp.com/https://api.dreamstime.com/api.xml?username=Mila486&password=5e85ea42732e5a1a67b284b7b9f03a85&type=get&request=search&srh_field=car&video=only&pg=1';

    // Функция для получения данных с API
    const fetchData = async () => {
      try {
        // Отправка запроса на сервер с использованием axios
        const response = await axios.get(url);
        
        // Вставка полученных данных в состояние
        setData(response.data);
      } catch (error) {
        // Обработка ошибок
        setError('Ошибка при получении данных: ' + error.message);
      } finally {
        // Завершение загрузки
        setLoading(false);
      }
    };

    // Вызов функции для загрузки данных
    fetchData();
  }, []);

  if (loading) {
    return <div>Загрузка данных...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Результаты поиска с Dreamstime</h1>
      <div>
        {data && data.includes('<items>') ? (
          <div>
            <h2>Изображения:</h2>
            {data.split('<item>').map((item, index) => {
              if (item.includes('<imageid>')) {
                // Извлечение данных для каждого элемента
                const imageUrl = item.match(/<mediumThumb>(.*?)<\/mediumThumb>/);
                const title = item.match(/<title>(.*?)<\/title>/);
                const imageId = item.match(/<imageid>(.*?)<\/imageid>/);
                
                return (
                  <div key={index}>
                    <h3>{title ? title[1] : 'Без названия'}</h3>
                    <img src={imageUrl ? imageUrl[1] : ''} alt={title ? title[1] : 'Изображение'} />
                    <p>ID изображения: {imageId ? imageId[1] : 'Неизвестно'}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <p>Нет данных для отображения</p>
        )}
      </div>
    </div>
  );
};

export default DreamstimeAPI;
